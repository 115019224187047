<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="{name:'bidbondtemplates'}">Bidbond Templates</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Create Template</a></li>
    </ol>
    <h1 class="page-header">Bidbond Templates</h1>
    <panel noButton="true" title="Create Bidbond Template" variant="panel-default">
      <div class="row">
        <div class="col-md-8">
          <div v-html="$error.handle(error)"/>
          <div class="form-group">
            <label>Template Name</label>
            <input
                v-model="name"
                class="form-control"
                type="text"
            >
          </div>
          <div class="form-group">
            <label>Counter Party(s)</label>
            <v-select
                v-model="selected_counter_parties"
                v-validate="'required'"
                :options="counterparties"
                label="name"
                name="selected_counter_parties"
                multiple
                placeholder="Select Counter Party(s)"
            />
            <small
                v-if="errors.has('procuring_entity')"
                class="text-danger"
            >{{ errors.first('procuring_entity') }}
            </small>
          </div>
          <div class="form-group">
            <tinymce
                id="one"
                v-model="my_data"
                :other_options="editor_options"
                html-class="large_editor"
            />
          </div>
          <div class="form-group">
            <a
                class="btn btn-primary"
                href="#"
                @click.prevent="submit"
            >Save</a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="variables">
            <h4>What you can use</h4>
            <table class="table table-bordered">
              <tbody>
              <tr
                  v-for="(variable, definition) in variables"
                  :key="variable"
              >
                <td>
                  <a
                      :id="variable"
                      class="copy"
                      href="#"
                      title="copy"
                      @click.prevent="copyVariable(variable)"
                  ><i class="fa fa-copy"/></a>
                  {{ definition }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>

<script>
import tinymce from 'vue-tinymce-editor'
import { editor_data, copyVar } from "@/mixins/editor";
export default {
  components: {
    tinymce
  },
  data() {
    return editor_data;
  },
  mounted() {
    this.init();
    this.fetchCounterParties();
  },
  computed: {
    counterparties() {
      return this.$store.getters.getCounterParties;
    },
  },
  methods: {
    init() {
      this.$axios.get('/api/v1/bid-bond-templates/create').then(response => {
        this.variables = response.data;
      });
    },
    selectAll(){
      this.selected_counter_parties = this.counterparties;
    },
    unSelectAll(){
      this.selected_counter_parties = [];
    },
    fetchCounterParties() {
      if (!this.counterparties.length) {
        this.$store.dispatch("fetchCounterParties").then(() => {
          this.loading = false;
        });
      }
    },
    copyVariable(val) {
      copyVar(val);
    },
    submit() {
      this.$axios.post('/api/v1/bid-bond-templates', {
        name: this.name,
        content: this.my_data,
        counter_parties : this.selected_counter_parties,
      }).then(response => {
        this.$router.push({name: 'bidbondtemplates.show', params: {id: response.data.data.secret}});
      }).catch(error => {
        this.error = error.response;
      });
    }
  }
}
</script>

<style lang="scss">
@import "src/scss/bidtemplate.scss";
</style>